@import '/node_modules/ngx-toastr/toastr.css';

#toast-container {
  opacity: 1;
  z-index: 100000;
}

.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}

.toast-success {
  background-color: #51A351 !important;
}

.toast-error {
  background-color: #BD362F !important;
}

.toast-info {
  background-color: #2F96B4 !important;
}

.toast-warning {
  background-color: #F89406 !important;
}