$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

/* media queries */
@mixin phone-down{
  @media(max-width: $sm){
    @content;
  }
}

@mixin tablet-up{
  @media(min-width: $sm){
    @content;
  }
}

@mixin small-desktop-up{
  @media(min-width: $md){
    @content;
  }
}

@mixin desktop-up{
  @media(min-width: $xl){
    @content;
  }
}

@mixin circle-object($size){
  height: $size;
  width: $size;
  border-radius: 50%;
}

@mixin background-config(){
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
