@font-face {
  font-family: 'fontello';
  src: url('../../../assets/fonts/fontello/fontello.eot?51287831');
  src: url('../../../assets/fonts/fontello/fontello.eot?51287831#iefix') format('embedded-opentype'),
       url('../../../assets/fonts/fontello/fontello.woff2?51287831') format('woff2'),
       url('../../../assets/fonts/fontello/fontello.woff?51287831') format('woff'),
       url('../../../assets/fonts/fontello/fontello.ttf?51287831') format('truetype'),
       url('../../../assets/fonts/fontello/fontello.svg?51287831#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../assets/fonts/fontello/fontello.svg?51287831#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-print:before { content: '\e800'; } /* '' */
.icon-cog-alt:before { content: '\e801'; } /* '' */
.icon-chart-bar-1:before { content: '\e802'; } /* '' */
.icon-cancel:before { content: '\e803'; } /* '' */
.icon-plus:before { content: '\e804'; } /* '' */
.icon-key:before { content: '\e805'; } /* '' */
.icon-question-circled:before { content: '\e806'; } /* '' */
.icon-warning:before { content: '\e807'; } /* '' */
.icon-wi-fi:before { content: '\e808'; } /* '' */
.icon-trash:before { content: '\e809'; } /* '' */
.icon-tools:before { content: '\e80a'; } /* '' */
.icon-tag:before { content: '\e80b'; } /* '' */
.icon-table-bell:before { content: '\e80c'; } /* '' */
.icon-search:before { content: '\e80d'; } /* '' */
.icon-export:before { content: '\e80e'; } /* '' */
.icon-picture:before { content: '\e80f'; } /* '' */
.icon-phone:before { content: '\e810'; } /* '' */
.icon-phone-checked:before { content: '\e811'; } /* '' */
.icon-people:before { content: '\e812'; } /* '' */
.icon-pencil:before { content: '\e813'; } /* '' */
.icon-operational:before { content: '\e814'; } /* '' */
.icon-move-arrow:before { content: '\e815'; } /* '' */
.icon-menu-hamburger:before { content: '\e816'; } /* '' */
.icon-flag:before { content: '\e817'; } /* '' */
.icon-exit:before { content: '\e818'; } /* '' */
.icon-credit-card:before { content: '\e819'; } /* '' */
.icon-cog:before { content: '\e81a'; } /* '' */
.icon-clone:before { content: '\e81b'; } /* '' */
.icon-clock:before { content: '\e81c'; } /* '' */
.icon-circle-chart:before { content: '\e81d'; } /* '' */
.icon-check:before { content: '\e81e'; } /* '' */
.icon-case:before { content: '\e81f'; } /* '' */
.icon-calendar:before { content: '\e820'; } /* '' */
.icon-bell:before { content: '\e821'; } /* '' */
.icon-bars:before { content: '\e822'; } /* '' */
.icon-arrow-right:before { content: '\e823'; } /* '' */
.icon-info-circled:before { content: '\e824'; } /* '' */
.icon-hamburger:before { content: '\e825'; } /* '' */
.icon-arrow-left:before { content: '\e826'; } /* '' */
.icon-check-circled:before { content: '\e827'; } /* '' */
.icon-order-person:before { content: '\e828'; } /* '' */
.icon-close-circled:before { content: '\e829'; } /* '' */
.icon-loading:before { content: '\e82a'; } /* '' */
.icon-note:before { content: '\e82b'; } /* '' */
.icon-bill:before { content: '\e82c'; } /* '' */
.icon-ticket:before { content: '\e82d'; } /* '' */
.icon-pdf:before { content: '\e82e'; } /* '' */
.icon-permissions:before { content: '\e82f'; } /* '' */
.icon-box:before { content: '\e830'; } /* '' */
.icon-usuario:before { content: '\e831'; } /* '' */
.icon-down-open:before { content: '\e832'; } /* '' */
.icon-left-open:before { content: '\e833'; } /* '' */
.icon-right-open:before { content: '\e834'; } /* '' */
.icon-up-open:before { content: '\e835'; } /* '' */
.icon-excel:before { content: '\e836'; } /* '' */
.icon-chart-bar:before { content: '\e837'; } /* '' */
.icon-money-sack:before { content: '\e838'; } /* '' */
.icon-like:before { content: '\e839'; } /* '' */
.icon-pending:before { content: '\e83a'; } /* '' */
.icon-reprint:before { content: '\e83b'; } /* '' */
.icon-money-pin:before { content: '\e83c'; } /* '' */
.icon-menu:before { content: '\e83d'; } /* '' */
.icon-minus:before { content: '\e83e'; } /* '' */
.icon-tax:before { content: '\e83f'; } /* '' */
.icon-organizations:before { content: '\e840'; } /* '' */
.icon-monitor:before { content: '\e841'; } /* '' */
.icon-thumbs-up:before { content: '\e842'; } /* '' */
.icon-thumbs-down:before { content: '\e843'; } /* '' */
.icon-download:before { content: '\e844'; } /* '' */
.icon-upload:before { content: '\e845'; } /* '' */
.icon-edit:before { content: '\e846'; } /* '' */
.icon-volume-off:before { content: '\e847'; } /* '' */
.icon-volume-down:before { content: '\e848'; } /* '' */
.icon-volume-up:before { content: '\e849'; } /* '' */
.icon-down-dir:before { content: '\e84a'; } /* '' */
.icon-up-dir:before { content: '\e84b'; } /* '' */
.icon-left-dir:before { content: '\e84c'; } /* '' */
.icon-right-dir:before { content: '\e84d'; } /* '' */
.icon-right-hand:before { content: '\e84e'; } /* '' */
.icon-left-hand:before { content: '\e84f'; } /* '' */
.icon-up-hand:before { content: '\e850'; } /* '' */
.icon-down-hand:before { content: '\e851'; } /* '' */
.icon-desktop:before { content: '\e852'; } /* '' */
.icon-laptop-1:before { content: '\e853'; } /* '' */
.icon-tablet-1:before { content: '\e854'; } /* '' */
.icon-mobile-1:before { content: '\e855'; } /* '' */
.icon-tablet-2:before { content: '\e856'; } /* '' */
.icon-exchange:before { content: '\e857'; } /* '' */
.icon-adult:before { content: '\e858'; } /* '' */
.icon-down-open-lg:before { content: '\f004'; } /* '' */
.icon-up-open-lg:before { content: '\f005'; } /* '' */
.icon-right-open-lg:before { content: '\f006'; } /* '' */
.icon-left-open-lg:before { content: '\f007'; } /* '' */
.icon-th-thumb:before { content: '\f00a'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-mic:before { content: '\f130'; } /* '' */
.icon-mute:before { content: '\f131'; } /* '' */
.icon-angle-circled-left:before { content: '\f137'; } /* '' */
.icon-angle-circled-right:before { content: '\f138'; } /* '' */
.icon-angle-circled-up:before { content: '\f139'; } /* '' */
.icon-angle-circled-down:before { content: '\f13a'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-discover:before { content: '\f1f2'; } /* '' */
.icon-cc-amex:before { content: '\f1f3'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-cc-stripe:before { content: '\f1f5'; } /* '' */
.icon-percent:before { content: '\f295'; } /* '' */
