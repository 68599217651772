//COLORS
$primary-color: #fca636;
$secondary-color: #fb6728;
$third-color: #4A90E2;

$dark-color: #333333;
$light-color: #FBFBFB;

$light-gray-color: #f5f5f5;
$gray-color: #DDDDDD;
$dark-gray-color: #999999;

$info-color: #4A90E2;
$success-color: #7ED321;
$warning-color: #F5A623;
$danger-color: #fb5224;

$active-color: #5CB100;
$secondary-active-color: #fed39b;
$inactive-color: #F5A623;
$disabled-color: #fb5224;

$hover-color: #fcf5eb;

//SIZES
$nav-height: 65px;
$sidemenu-width: 200px;
$sidemenu-width-reduced: 60px;

$large-radius: 12px;
$medium-radius: 8px;
$small-radius: 6px;

// ================================== //
$primary       : #fca636;
$primary-dark  : #fb5224;
$secondary     : #fb6728;
$secondary-dark: #f95124;

$white: #ffffff;
$black: #181818;

$gray-1: #FAFAFA;
$gray-2: #F5F5F5;
$gray-3: #9B9B9B;
$gray-4: #333333;
$gray-5: #212529;

$info        : #4A90E2;
$info-dark   : #2E5F98;
$success     : #5CB100;
$success-dark: #417C00;
$warning     : #F5A622;
$warning-dark: #BF811B;
$danger      : #fb5224;
$danger-dark : #fb5224;

$success-dark-purple: #8844a2;
$success-purple: #987ea3;
