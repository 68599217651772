@import '~@ng-select/ng-select/themes/default.theme.css';

.ng-select{
  position: relative;

  &:after{
    content: '';
    position: absolute;
    border: 2px solid $primary;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    border-top: none;
    border-left: none;
    top: 15px;
    right: 15px;
    z-index: 1001;
  }

  .ng-clear-wrapper,
  .ng-arrow-wrapper{
    display: none;
  }

  .ng-select-container{
    border-radius: $small-radius;
    border: none !important;
    background-color: $gray-2 !important;
    width: 100%;
    transition: .3s ease;
    height: 43px !important;
  }

  .ng-select-disabled .ng-select-container{
    background-color: rgba($gray-3, 0.4);
    cursor: not-allowed;
  }

  .ng-value-container{
    padding-left: 15px;
  }

  .ng-placeholder{
    color: $gray-3 !important;
  }

  .ng-input{
    padding-top: 4px !important;
  }

  .ng-dropdown-panel{
    border: none !important;
    background: transparent !important;

    .ng-option{
      background-color: $gray-2;


      &:hover{background-color: lighten($primary, 20%) !important}
    }

    .ng-dropdown-panel-items{
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.ng-option-disabled{pointer-events: none;}
.ng-option.ng-option-marked,
.ng-option.ng-option-selected {
  background-color: lighten($primary, 20%) !important;
}

.ng-option.ng-option-selected {
  font-weight: bold;  
}

.ng-dropdown-panel{box-shadow: none !important;}

.ng-select-focused,
.ng-select-opened{
  .ng-dropdown-panel-items{
    box-shadow: 0 6px 10px -2px rgba($black, 0.12);
  }
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
  border: none !important;
  box-shadow: none !important;
}

// select wrap
.select-wrap{
  .ng-dropdown-panel{
    .ng-dropdown-panel-items{
      .ng-option {
        white-space: normal !important;
      }
    }
  }
}

// ----- SELECT MULTIPLE
.ng-select-multiple {
  .ng-select-container{
    min-height: 43px !important;
    height: auto !important;
    padding-right: 40px !important;
  }

  .ng-placeholder{
    margin-top: 5px !important;
  }

  .ng-value-icon{
    background-color: rgba($primary, 0.4) !important;
    border-right: 1px solid $primary !important;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  .ng-value-label{
    background-color: rgba($primary, 0.4) !important;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}


// SELECT FROM EVENT MENU
.product-category-wrapper{
  .ng-select-container{
    padding: 0 7px;
    box-shadow: none;
    height: 30px !important;

  }

  .ng-value{
    width: 100%;
    text-align: center;
  }

  .ng-input{
    outline: none;
  }

  .ng-placeholder{
    text-align: center;
    width: 100%;
  }

  .ng-option{
    text-align: center;
  }
}
