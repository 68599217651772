@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,900');
@import './variables';
@import './mixins';
@import './form';
@import './tabs';

//vendor
@import '~datatables.net-dt/css/dataTables.dataTables.css';
@import './vendor/fontello/fontello.scss';
@import './vendor/smart-modal/smart-modal.scss';
@import './vendor/select/ng-select.scss';
@import './vendor/switch/ui-switch.scss';
@import './datatable';
@import './table';
@import './vendor/toastr/toastr.scss';

//components
@import './components/commons';
@import './components/header-tabs';
@import './components/buttons';
@import './components/form';

//directive
@import './directives/ss-loading';

//bootstrap
@import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

* {
  font-family: 'Roboto', sans-serif;
}

a:hover {
  text-decoration: none;
}

.ss-container {
  padding: 25px 20px;
  background-color: #f5f5f5;
  min-height: calc(100vh - #{$nav-height});
}

.card {
  border: none;
  border-radius: $small-radius;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.125);
}

.card-footer {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

//snotify
.snotify {
  width: 400px;
}

// box
.box-dashed {
  border: 2px dashed #979797;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
}

// ----- HELPERS
.primary-color {
  color: $primary-color;
}

.info-color {
  color: $info-color;
}

.success-color {
  color: $success-color;
}

.warning-color {
  color: $warning-color;
}

.danger-color {
  color: $danger-color;
}

.light-color {
  color: $light-color;
}

.light-gray-color {
  color: $light-gray-color;
}

.dark-gray-color {
  color: $dark-gray-color;
}

.bg-primary-color {
  background-color: $primary-color;
}

.bg-info-color {
  background-color: $info-color;
}

.bg-success-color {
  background-color: $success-color;
}

.bg-warning-color {
  background-color: $warning-color;
}

.bg-danger-color {
  background-color: $danger-color;
}

.bg-light-color {
  background-color: $light-color;
}

.bg-light-gray-color {
  background-color: $light-gray-color;
}

.bg-dark-gray-color {
  background-color: $dark-gray-color;
}

.text-nowrap {
  white-space: nowrap;
}

.text-underline {
  text-decoration: underline;
}

.font-xlarge {
  font-size: 1.6rem;
}

.font-large {
  font-size: 1.4rem;
}

.font-slarge {
  font-size: 1.2rem;
}

.font-small {
  font-size: 0.85rem;
}

.font-light {
  font-weight: 300;
}

.font-bold {
  font-weight: 500;
}

.font-bolder {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.uppercase {
  text-transform: uppercase;
}

.img-responsive {
  height: auto;
  max-width: 100%;
}

//hover tip directive
.ss-hover-tip {
  position: absolute;
  border-radius: 10px;
  background-color: #efefef;
  padding: 12px;
  color: #333;
  font-weight: 300;
  font-size: 0.9rem;
  max-width: 200px;
  box-shadow: -5px 10px 22px 0 rgba(30, 30, 30, 0.1);
}


.ss-menu__product-form {
  .ss-input-group .ng-select .ng-select-container {
    background-color: #fff;
    padding: 5px;
    width: 150px;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding: 0;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input input {
    text-align: center;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container {
    padding: 0;
  }
}

// ----- Reports Card
.card-head {
  padding: 15px 0;
  margin: 0 20px;
  border-bottom: 1px solid #c9c9c9;
}

.card-head-overview {
  display: flex;
  align-items: center;
}

.card-head-overview__title {
  color: $primary-color;
  margin-right: auto;
}

.card-head-overview__icon {
  color: lightgray;
  cursor: pointer;
  transition: .3s ease-in-out;
}

.card-head-overview__icon.is-active {
  color: $primary-color;
}


// ----- FALLBACK
.ss-empty-chart,
.ss-empty-table {
  height: 210px;
  padding: 15px;
  color: #c9c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ----- BLOCKED CONTENT
.blocked-content {
  position: relative;
  opacity: 0.5;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }
}

// ----- PERCENT BAR
.ss-percent-container {
  position: relative;
  height: 20px;
  border-radius: 10px;
  background-color: $gray-color;
  width: 100%;
}

.ss-percent-bar {
  position: absolute;
  height: 20px;
  border-radius: 10px;
}

.ss-percent-value {
  min-width: 40px;
  text-align: right;
}

.disabled-option {
  color: #ccc;
  pointer-events: none;
}

.loader-button {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-top: 2px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

//modal
.nsm-content {
  border-radius: $medium-radius;
}