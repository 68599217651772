@mixin button-shadow($color) {
  box-shadow: 0 0 10px 0px rgba($color, 0.6);
}

@mixin button-disabled {
  &:disabled {
    background-color: rgba($gray-3, 0.4) !important;
    color: $gray-3 !important;
    cursor: not-allowed;
  }
}

@mixin button-outline-disabled {
  &:disabled {
    border-color: $gray-3 !important;
    background-color: transparent !important;
    color: $gray-3 !important;
    cursor: not-allowed;
  }
}

button {
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

.ss-btn,
.ss-btn-icon,
.ss-btn-modal {
  border-radius: 6px;
  transition: .3s ease;
  outline: none !important;
}

.ss-btn {
  padding: 9px 30px;
  font-weight: 500;
  text-align: center;
  border: 3px solid transparent;
}

.ss-btn-icon {
  padding: 6px;
  border: 2px solid transparent;
  border-radius: 6px;
  display: inline-flex;
  transition: background-color 0.2s;

  &:before {
    font-size: 18px;
  }

  &:hover {
    background-color: $primary-dark !important;
  }
}

.ss-btn-modal {
  font-size: 17px;
  min-width: 140px;
  padding: 15px 30px;
  margin: 0 15px;
  font-weight: 500;
  text-align: center;
  border: 3px solid transparent;
}

.ss-btn.icon-and-text {
  padding: 5px 20px;

  &:before {
    font-size: 18px;
    margin-right: 10px;
    margin-left: 0;
  }
}


// primary
.ss-btn-modal.primary,
.ss-btn-icon.primary,
.ss-btn.primary {
  background-color: $primary;
  color: $white;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $primary-dark !important;
  }

  &:focus,
  &:active {
    @include button-shadow($primary);
  }

  @include button-disabled;
}

// outline primary
.ss-btn-modal.outline-primary,
.ss-btn-icon.outline-primary,
.ss-btn.outline-primary {
  color: $primary;
  border-color: $primary;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $primary !important;
    color: $white !important;
  }

  &:focus,
  &:active {
    @include button-shadow($primary);
  }

  @include button-outline-disabled;
}

// secondary
.ss-btn-modal.secondary,
.ss-btn-icon.secondary,
.ss-btn.secondary {
  background-color: $secondary;
  color: $white;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $secondary-dark !important;
  }

  &:focus,
  &:active {
    @include button-shadow($secondary);
  }

  @include button-disabled;
}

// outline secondary
.ss-btn-modal.outline-secondary,
.ss-btn-icon.outline-secondary,
.ss-btn.outline-secondary {
  color: $secondary;
  border-color: $secondary;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $secondary !important;
    color: $white !important;
  }

  &:focus,
  &:active {
    @include button-shadow($secondary);
  }

  @include button-outline-disabled;
}

// info
.ss-btn-modal.info,
.ss-btn-icon.info,
.ss-btn.info {
  background-color: $info;
  color: $white;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $info-dark !important;
  }

  &:focus,
  &:active {
    @include button-shadow($info);
  }

  @include button-disabled;
}

// info outline
.ss-btn-modal.outline-info,
.ss-btn-icon.outline-info,
.ss-btn.outline-info {
  color: $info;
  border-color: $info;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $info !important;
    color: $white !important;
  }

  &:focus,
  &:active {
    @include button-shadow($info);
  }

  @include button-outline-disabled;
}

// suceess
.ss-btn-modal.success,
.ss-btn-icon.success,
.ss-btn.success {
  background-color: $success;
  color: $white;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $success-dark !important;
  }

  &:focus,
  &:active {
    @include button-shadow($success);
  }

  @include button-disabled;
}

// outline suceess
.ss-btn-modal.outline-success,
.ss-btn-icon.outline-success,
.ss-btn.outline-success {
  color: $success;
  border-color: $success;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $success !important;
    color: $white !important;
  }

  &:focus,
  &:active {
    @include button-shadow($success);
  }

  @include button-outline-disabled;
}

// warning
.ss-btn-modal.warning,
.ss-btn-icon.warning,
.ss-btn.warning {
  background-color: $warning;
  color: $white;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $warning-dark !important;
  }

  &:focus,
  &:active {
    @include button-shadow($warning);
  }

  @include button-disabled;
}

// outline warning
.ss-btn-modal.outline-warning,
.ss-btn-icon.outline-warning,
.ss-btn.outline-warning {
  color: $warning;
  border-color: $warning;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $warning !important;
    color: $white !important;
  }

  &:focus,
  &:active {
    @include button-shadow($warning);
  }

  @include button-outline-disabled;
}

// danger
.ss-btn-modal.danger,
.ss-btn-icon.danger,
.ss-btn.danger {
  background-color: $danger;
  color: $white;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $danger-dark !important;
  }

  &:focus,
  &:active {
    @include button-shadow($danger);
  }

  @include button-disabled;
}

// outline danger
.ss-btn-modal.outline-danger,
.ss-btn-icon.outline-danger,
.ss-btn.outline-danger {
  color: $danger;
  border-color: $danger;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $danger !important;
    color: $white !important;
  }

  &:focus,
  &:active {
    @include button-shadow($danger);
  }

  @include button-outline-disabled;
}

// black
.ss-btn-modal.black,
.ss-btn-icon.black,
.ss-btn.black {
  background-color: $black;
  color: $white;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:focus,
  &:active {
    @include button-shadow($black);
  }

  @include button-disabled;
}

// outline black
.ss-btn-modal.outline-black,
.ss-btn-icon.outline-black,
.ss-btn.outline-black {
  color: $black;
  border-color: $black;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $black !important;
    color: $white !important;
  }

  &:focus,
  &:active {
    @include button-shadow($black);
  }

  @include button-outline-disabled;
}

// gray
.ss-btn-modal.gray,
.ss-btn-icon.gray,
.ss-btn.gray {
  background-color: $gray-3;
  color: $white;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:focus,
  &:active {
    @include button-shadow($gray-3);
  }

  @include button-disabled;
}

// outline gray
.ss-btn-modal.outline-gray,
.ss-btn-icon.outline-gray,
.ss-btn.outline-gray {
  color: $gray-3;
  border-color: $gray-3;
  padding: 6px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover,
  &:active {
    background-color: $gray-3 !important;
    color: $white !important;
  }

  &:focus,
  &:active {
    @include button-shadow($gray-3);
  }

  @include button-outline-disabled;
}