// ========== TITLE (subtitle - return btn) ========== //
.ss-title__subtitle{
  font-style: italic;
  font-weight: 200;
  font-size: 1.25rem;
}

.ss-title__return-btn{
  cursor: pointer;
  background-color: $primary-color;
  padding: 2px 0;
  border-radius: $medium-radius;
  margin-right: 8px;
  transition: .3s ease-in-out;
  width: 35px;
  height: 35px;
  position: relative;

  &:before{
    content: '\e833';
    color: $light-color;
    font-family: 'fontello';
    font-size: 30px;
    position: absolute;
    top: 0px;
    left: 10px;
    transition: .2s ease;
  }

  &:after{
    content: '';
    position: absolute;
    background-color: #FBFBFB;
    height: 4px;
    width: 0;
    top: 16px;
    left: 11px;
    border-radius: 5px;
    transition: .2s ease;
  }

  &:hover{
    background-color: darken($primary-color, 15%);
    &:before{left: 7px;}
    &:after{width: 16px;}
  }
}

// ========== PRODUCT THUMBNAIL ========== //
.ss-product-thumbnail-round{
  height: 50px;
  width: 50px;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.05);
}

// =========================================== //
// margins and paddings
@for $i from 1 through 15 {
  .m-#{$i * 5}{margin: $i * 5px;}
  .mr-#{$i * 5}{margin-right: $i * 5px;}
  .ml-#{$i * 5}{margin-left: $i * 5px;}
  .mt-#{$i * 5}{margin-top: $i * 5px;}
  .mb-#{$i * 5}{margin-bottom: $i * 5px;}
  .mh-#{$i * 5}{margin-left: $i * 5px; margin-right: $i * 5px;}
  .mv-#{$i * 5}{margin-top: $i * 5px; margin-bottom: $i * 5px;}

  .p-#{$i * 5}{padding: $i * 5px;}
  .pr-#{$i * 5}{padding-right: $i * 5px;}
  .pl-#{$i * 5}{padding-left: $i * 5px;}
  .pt-#{$i * 5}{padding-top: $i * 5px;}
  .pb-#{$i * 5}{padding-bottom: $i * 5px;}
  .ph-#{$i * 5}{padding-left: $i * 5px; padding-right: $i * 5px;}
  .pv-#{$i * 5}{padding-top: $i * 5px; padding-bottom: $i * 5px;}
}

.flex{display: flex;}

// ========== CARD ========== //
.ss-card{
  box-shadow: 0 5px 5px 2px rgba($black, 0.1);
  background-color: $white;
}

.ss-card-body{
  padding: 20px;
}

.ss-card-footer{
  padding: 20px;
}
