.ss-tabs{
  border-bottom: 1px solid #c9c9c9;
  padding: 0 10px;
  margin-bottom: 15px;

  .ss-tab{
    cursor: pointer;
    padding: 5px 25px;
    display: inline-block;
    margin-bottom: -5px;
  }

  .ss-tab.is-active{
    font-weight: 500;
    border-bottom: 10px solid $primary-color; //TEMPORARIO
    border-radius: 6px;
  }
}
