.ss-loading{
  position: relative;
  overflow: hidden;

  &:before,
  &:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    opacity: 1;
    transition: .2s ease;
  }

  &:before{
    background: rgba($light-color ,0.8);
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  &:after{
    z-index: 2;
    top: 50%;
    border: 5px solid $dark-gray-color;
    border-top-color: $secondary-color;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    margin: -20px auto 0;
    animation: spin 1.5s linear infinite;
  }
}

.ss-loading-done{
  overflow: initial;
  &:before,
  &:after{
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
