.ss-table {
  .ui-table-wrapper {
    border-radius: $small-radius;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }

  th {
    background: $dark-color;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 12px !important;
    text-align: left;
  }

  .sortable {
    cursor: pointer;
    position: relative;
    padding-left: 20px !important;

    &:after {
      content: '';
      border: 5px solid transparent;
      border-bottom-color: darken($light-color, 50%);
      top: 50%;
      margin-top: -12px;
      left: 5px;
      position: absolute;

    }

    &:before {
      content: '';
      border: 5px solid transparent;
      border-top-color: darken($light-color, 50%);
      top: 50%;
      margin-top: 2px;
      left: 5px;
      position: absolute;
    }
  }

  .sortable.is-asc {
    &:after {
      border-bottom-color: $light-color;
    }
  }

  .sortable.is-desc {
    &:before {
      border-top-color: $light-color;
    }
  }

  .sortable.text-center {
    padding-left: 10px !important;
  }

  tbody {
    tr {
      transition: background-color 0.2s ease;
      font-size: 14px;

      &:nth-child(even) {
        background-color: $light-color;
      }

      &:hover {
        background-color: $hover-color;
      }
    }

    td {
      padding: 12px !important;
      color: #424242;
    }
  }

  // ----- paginator
  .p-paginator-bottom {
    margin-top: 15px;
  }

  .p-paginator-pages {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-paginator-element {
    border: 1px solid $gray-color;
    border-right: none;
    margin-top: 20px !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    height: 30px !important;
    width: 30px !important;
    line-height: 30px !important;
    color: $dark-color;
    outline: none;

    &:hover {
      background-color: $light-gray-color;
      color: inherit;
    }
  }

  .p-highlight {
    color: $secondary-color;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    cursor: pointer;
    padding: 8px 12px;
    margin: 0 4px;
    transition: background-color 0.3s;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-last {
    background-color: #F5F5F5;
  }

  .p-paginator .p-paginator-last {
    border-right: 1px solid $gray-color;
    border-top-right-radius: $small-radius;
    border-bottom-right-radius: $small-radius;
  }

  .p-paginator .p-paginator-first {
    border-top-left-radius: $small-radius;
    border-bottom-left-radius: $small-radius;
  }

  .p-paginator .p-paginator-first:hover,
  .p-paginator .p-paginator-prev:hover,
  .p-paginator .p-paginator-next:hover,
  .p-paginator .p-paginator-last:hover {
    background-color: #f0f0f0;
  }

  .p-paginator a.p-state-disabled {
    &:before {
      color: #aaacae !important;
    }
  }

  .ss-table--empty {
    font-size: 1.3rem;
    color: #757575;
    text-align: center;
    padding: 20px;
  }
}