// ----- Datatable

// ----- length
.ss-length {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.dataTables_length {
  margin-bottom: .5em;

  select {
    background: $light-color;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    margin: 0 5px;
    border: 1px solid #e5eced;
    height: 35px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 0 15px 0 10px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAVCAYAAACZm7S3AAAAe0lEQVQ4jdWSzQ2AIAyFvxJ30gV0aSdwqnowmlDaQONFe+P9UV6AX44A6Dp7nFpdZdwPShCqnTOAa3aFHm7NkdHlS0SMBBQLZAKutjXrBREJ2x6aV+YJgG3J7w1y39z8oJ4R6rVHAx6dfXMvoOK9wqKABo/atsJsJ1+eExEwEp2MDocXAAAAAElFTkSuQmCC') no-repeat;
    background-position: 90% center;
    background-size: 10px 16px;
  }
}

// ----- table head
table.dataTable thead .sorting_asc {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAVCAYAAACZm7S3AAAAeElEQVQ4jdWSSw7AIAhEH6be/wAe1QVdtakIUdJNOzuZjzgRfgkB6L17nFrdE7VWShCqizOAa3aF3tyaI6PLl4jYCSh2kAkQANWsF0QkbHsLr8wHQGstvzfIdfP0g1ZGGNfeDbh19s2rgIH3CosCpnnUthVmO/kyTiyaE/B3y+WPAAAAAElFTkSuQmCC');
  background-size: 10px 15px;
}

table.dataTable thead .sorting_desc {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAVCAYAAACZm7S3AAAAeElEQVQ4jdWSSw7AIAhEH6Zn9VCe1YSu2lSEKOmmnZ3MR5wIv4QAtNY8Tq3uiVorJQjVxRnANbtCb27NkdHlS0TsBBQ7yAQIgGrWCyIStr2FV+YDoPee3xvkunn6QSsjjGvvBtw6++ZVwMB7hUUB0zxq2wqznXwZJ1rZFRMphs0uAAAAAElFTkSuQmCC');
  background-size: 10px 15px;
}

table.dataTable thead .sorting {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAVCAYAAACZm7S3AAAAd0lEQVQ4jdWSSw7AIAhEH6Zn9VB6WLpqUxGipJt2djIfcSL8EgLQWvM4tbonaq2UIFQXZwDX7Aq9uTVHRpcvEbETUOwgEyAAqlkviEjY9hZemQ+A3nt+b5Dr5ukHrYwwrr0bcOvsm1cBA+8VFgVM86htK8x28mWc8W8T8PbQpIcAAAAASUVORK5CYII=');
  background-size: 10px 15px;
}

.dataTables_scrollHead {
  border: 1px solid $dark-color;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: $dark-color;
  color: #ffffff;
  padding: 0 15px;

  th {
    font-weight: 300 !important;
    text-transform: uppercase;
    font-size: .95rem;
    cursor: default;
    text-decoration: none !important;
  }
}

// ----- table body
.dataTables_scrollBody {
  border: 1px solid #eee !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 15px;
  font-size: .9rem;

}

// ----- pagination
.ss-pagination {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 1.5em;

  .paginate_button,
  .ellipsis {
    padding: 0.3em 0.8em !important;
    border: 1px solid #e8e8e8 !important;
    border-left: none !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }

  .ellipsis {
    padding: 0.5em 0.8em !important;
  }

  .paginate_button.next {
    color: $primary-color !important;
    background-color: #e8e8e8 !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .paginate_button.previous {
    color: $primary-color !important;
    border-left: 1px solid #e8e8e8 !important;
    background-color: #e8e8e8 !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .paginate_button.disabled {
    color: #aaacae !important;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #e8e8e8 !important;
  color: $dark-color !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  color: #aaacae !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled.next:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled.previous:hover {
  color: #aaacae !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover {
  color: $primary-color !important;
}

.paginate_button.current {
  background: none !important;
  color: $primary-color !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: transparent !important;
  color: $primary-color !important;
  cursor: default !important;
}

.first.paginate_button,
.last.paginate_button {
  display: none !important;
}

.dt-scroll {
  border: 0px solid #dddddd;
  border-left-width: 1px;
  border-right-width: 1px;
}

.dt-scroll-headInner {
  background-color: #333333;
  color: #e5eced;
}

.dt-column-title {
  font-weight: 500;
}

// ----- others
.ss-datatable__link {
  cursor: pointer;
  text-decoration: underline;
  color: #212529;
}

.dataTables_empty {
  display: none;
}

.row-border {
  border-bottom: none !important;
}

.row-border tbody tr td {
  border-top: none !important;
}

.row-border tbody tr:hover {
  background-color: #fcf5ebff !important;
}

.dt-scroll {
  border: none !important;
}

.dt-scroll-body {
  border: none !important;
}

@include desktop-up {
  .ss-pagination {
    flex-direction: row;
    position: relative;

    .ss-info {
      position: absolute;
      left: 0;
      top: -2px;
    }
  }
}