.ss-header-tabs{
  padding: 0 0.8rem;
  display: flex;
}
.ss-header-tab{
  border-top-left-radius: $large-radius;
  border-top-right-radius: $large-radius;
  background-color: #ffffff;
  color: $primary-color;
  padding: 0.8rem 1.2rem;
  min-width: 120px;
  max-width: 300px;
  text-align: center;
  transition: .3s ease-in-out;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 0.8rem;
  margin: 0 7px;
  font-weight: 500;
}

.ss-header-tab.is-active{
  color: $light-color;
  background-color: $primary-color;
}

.ss-header-tab.is-disabled{
  color: #9d9d9d;
  background-color: #d1d1d1;
  cursor: not-allowed;
}
