@import '/node_modules/ngx-smart-modal/styles/ngx-smart-modal.css';

.nsm-dialog{margin: 100px auto 40px;}

.nsm-content{
  border-radius: 20px;
  padding: 60px;

  .nsm-dialog-btn-close{display: none;}
  .modal-btn-close{
    position: absolute;
    cursor: pointer;
    height: 30px;
    width: 30px;
    top: -30px;
    right: -30px;

    &:before{
      content: '\e803';
      font-family: 'fontello';
      color: #707070;
      font-size: 28px;
      line-height: 1;
      padding-left: 8px;
    }
  }

  table .btn{min-width: 0 !important;}
}

// ----- modal footer actions
.ss-modal__footer-actions{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

// ----- modal size
.small-modal{max-width: 500px}
.medium-modal{max-width: 650px}
.large-modal{max-width: 850px}
.huge-modal{max-width: 1000px}
.giant-modal{max-width: 1200px}


// ----- form modal feedback
.form-modal-feedback{
  margin: 0 auto;
  width: 250px;
  text-align: center;

  .form-modal-feedback__icon{width: 200px;}
}
