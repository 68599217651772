// form reset and base
input,
select,
textarea{
  vertical-align: middle;
	white-space: normal;
	background: none;
  line-height: 1;
  border: none;

  &:focus{
    outline: 0;
  }
}

textarea{
  resize: none;
}

// ----- input and text area
.ss-input-group{
  margin-bottom: 30px;
  position: relative;

  .ss-label{
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
    color: $gray-4;

    &[required]:after{
      content:' *';
      color: $primary;
    }
  }

  .ss-input, .ss-textarea{
    padding: 12px 15px;
    border-radius: $small-radius;
    width: 100%;
    color: $gray-4;
    background-color: $gray-2;
    transition: .3s ease;

    &:focus,
    &:active{
      box-shadow: 0 3px 6px 1px rgba($black, 0.12);
    }

    &:disabled{
      background-color: rgba($gray-3, 0.4);
      cursor: not-allowed;
    }

    &::placeholder{
      color: $gray-3;
    }
  }
}

// ----- input with icon
.ss-input-group.with-icon{

  .ss-input{
    padding-right: 45px;
  }

  .ss-input-icon,
  .ss-input-icon--clear{
    position: absolute;
    right: 0;
    bottom: 0;
    color: $primary;
    height: 43px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  .ss-input-icon--clear{
    right: 40px;
    width: 25px;
    cursor: pointer;
  }
}

// ----- pill
.ss-pill{
  cursor: pointer;
}

.ss-pill__input{
  display: none;

  &:checked + .ss-pill__label:before{
    content: '\E81E'; 
    font-family: 'fontello';
    left: 50%;
    background: $success;
    border: 2px solid $success;
    border-top-left-radius: 0;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 15px;
    padding-top: 3px;
  }
}

.ss-pill__label{
  height: 30px;
  width: 80px;
  border: 2px solid $gray-3;
  border-radius: 15px;
  display: block;
  position: relative;
  overflow: hidden;
  margin: .5rem 0.4rem;

  &:before{
    content: '\E803';
    font-family: 'fontello';
    position: absolute;
    width: 40px;
    height: 30px;
    background: $danger;
    text-align: center;
    border: 2px solid $danger;
    color: $light-color;
    top: -2px;
    left: -2px;
    border-top-left-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0;
    transition: .3s ease-in-out;
    padding-top: 3px;
  }
}

// ----- radio
.ss-radio-group{
  display: flex;
}

.ss-radio-group:hover .ss-radio{
  box-shadow: 0 3px 6px 1px rgba($black, 0.25);
}

.ss-radio-hidden{
  display: none;
}

.ss-radio{
  display: inline-block;
  height: 20px;
  min-width: 20px;
  width: 20px;
  border: 3px solid $gray-3;
  border-radius: 50%;
  margin-right: 10px;
  transition: .3s ease;
}

.ss-radio-hidden:checked ~ .ss-radio{
  border-color: $primary;
  background-image: radial-gradient($primary 50%, $white 20%, $white 50%) !important;
}

// ----- checkbox
.ss-checkbox-group{
  display: flex;
}

.ss-checkbox-group:hover .ss-checkbox{
  box-shadow: 0 3px 6px 1px rgba($black, 0.25);
}

.ss-checkbox-hidden{
  display: none;
}

.ss-checkbox{
  display: inline-block;
  height: 20px;
  min-width: 20px;
  width: 20px;
  border: 3px solid $gray-3;
  border-radius: 5px;
  margin-right: 10px;
}

.ss-checkbox-hidden:checked ~ .ss-checkbox{
  position: relative;

  &:before{
    content: '';
    position: absolute;
    border: 3px solid $primary;
    height: 7px;
    width: 12px;
    transform: rotate(-45deg);
    border-top: none;
    border-right: none;
    top: 2px;
    left: 1px;
  }
}

// ----- about filters
.table-filters-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.table-filters{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}


.ss-input-group.filter{
  margin: 0 15px 15px 0;
}

.ss-date-range-filter{
  display: flex;

  .ss-input-group{
    margin: 0 0 15px !important;
  }

  .separator{
    margin: 10px 10px 0;
  }
}
