// @import '~ngx-ui-switch/ui-switch.component.css';
@import '/node_modules/ngx-ui-switch/ui-switch.component.css';

.switch{
  border: 1px solid $gray-3;
  background-color: $gray-3;
  box-shadow: none;
  width: 60px;
  transition: .2s ease;
}

.switch:hover{
  box-shadow: 0 3px 6px 1px rgba($black, 0.25);
}

.switch.switch-medium small{
  width: 28px;
  height: 28px;
  right: calc(100% - 30px);
  top: 1px;
  border: 2px solid $white;
  background-color: $gray-3;
}

.switch.checked{
  background-color: lighten($primary, 20%);
  border: 1px solid lighten($primary, 20%);
}

.switch.checked.switch-medium small{
  right: 1px;
  border-color: $primary;
  background-color: $primary;
}
